<template>
  <div v-if="business">
    <ValidationObserver
        v-if="business"
        v-slot="{ handleSubmit, invalid, touched }"
        ref="updateForm"
    >
      <form
          class="authentication-form"
          @submit.prevent="handleSubmit(updateGate)"
      >
        <div class="row review-admin-contain">
          <div class="col-md-4 col-lg-4 col-xl-3 gate-left">
            <h4 class="text-white">Review Gate</h4>
            <ul class="list-unstyled">
              <li>
                <label class="d-flex align-items-center">Body Title<MergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" class="ml-2" @selected="addField" /></label>
                <TextInput v-model="business.business.review_gate_settings.body_title" class="w-100" />
              </li>
              <li>
                <div class="w-100">
                  <label class="d-flex align-items-center">Body Text<MergeFieldsGroup dropdown-size="sm" :show-keyword="true" :show-contact-link="true" :hide-title="true" button-variant="outline-light" :hide-google-links="true" class="ml-2" @selected="addField" /></label>
                  <BTextarea v-model="business.business.review_gate_settings.body_text" rows="6" class="w-100" />
                </div>
              </li>
              <li>
                <TextInput v-model="business.business.review_gate_settings.good_label" label="Good Experience Label" class="w-100" />
              </li>
              <li>
                <TextInput v-model="business.business.review_gate_settings.bad_label" label="Bad Experience Label" class="w-100" />
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-lg-8 col-xl-9 pr-lg-0">
            <div class="row justify-content-center bg-white pt-2">
              <div class="flex-1 align-self-center justify-content-center">
                <div class="d-inline-block mr-3 mb-3">
                  <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px;">
                    <span v-if="business.business.review_gate_enabled">Enabled</span>
                    <span v-else>Disabled</span>
                  </small>
                  <div class="d-inline-flex">
                    <span class="mr-2 d-flex font-weight-bold">Feedback Gate</span>
                    <b-form-checkbox
                        v-model="business.business.review_gate_enabled"
                        size="sm"
                        name="initial-button"
                        switch
                        inline
                    >
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="flex-1 align-self-center justify-content-center">
                <div class="d-inline-block mr-3 mb-3 text-center">
                  <div>
                    <span class="d-block font-weight-bold text-xl-left">Gate URL</span>
                    <div class="d-inline-block" style="vertical-align: middle">
                      {{ gatePreUrl }}
                      <input v-model="business.business.unique_id" type="text" :disabled="!user.business.editingUniqueId"/>
                      <button v-if="!user.business.editingUniqueId" type="button" class="btn btn-sm btn-link font-size-24" @click="user.business.editingUniqueId = true"><i class="uil uil-edit"></i></button>
                      <button v-else type="button" class="btn btn-sm btn-link font-size-24" @click="user.business.editingUniqueId = false"><i class="uil uil-times"></i></button>
                    </div>
                    <a :href="gateUrl" target="_blank" class="btn btn-sm btn-dark-blue">
                      <i class="uil uil-eye"></i>
                    </a>
                    <b-button
                        class="btn btn-dark-blue btn-sm ml-2"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Update</span>
                    </b-button>
                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <review-gate v-if="business" :business="business" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>

</template>

<script>

import Vue from "vue";
import ReviewGate from "@components/Business/ReviewGate.vue";
import MergeFieldsGroup from "@components/Business/Automations/MergeFieldsGroup.vue";
export default {
  components:{
    MergeFieldsGroup,
    ReviewGate
  },
  data(){
    return {
        user:null,
        business:null,
        loadingUpdate:false
    }
  },
  computed:{
    gatePreUrl(){
      return window.location.origin.includes('staging.') ? 'https://a618-103-196-161-200.ngrok-free.app/review/' : 'https://app.showmyrep.io/r/';
    },
    gateUrl(){
      return this.gatePreUrl+this.business.business.unique_id;
    }
  },
  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    this.getWidget();

  },
  methods:{
    addField(field){
      document.execCommand('insertText', false, field)
    },
    getWidget() {
      this.$store
          .dispatch('landingPage/getReviewGate', {business_id: this.user.business.unique_id, query_params: "is_admin=true"})
          .then((res) => {
            this.$set(this,'business',res.reviewGate);
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
            }
          })
    },
    getEmployees(){
      this.loadingEmployees = true

      this.$store
          .dispatch('employees/getAll', {
            queries: this.datatable.queries,
            page: 'index',
          })
          .then((res) => {
            this.loadingEmployees = false
          })
          .catch(() => {
            this.loadingEmployees = false
          })
    },
    updateGate(){
      this.loadingUpdate = true
      const business = this.business.business;
      this.$store
          .dispatch('business/gateUpdate', business)
          .then(() => {
            this.loadingUpdate = false
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
    }
  }
}
</script>
<style>
.review-admin-contain{
  margin-top: -10px;
  margin-left:-24.5px;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
}
body{
  padding-bottom: 0;
}
.gate-left{
  background: #284373;
  color:#fff;
}
.gate-left li{
  background:#52688f;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 10px;
}
.eyebtn{
  margin-top: -5px;
}
</style>
